import React, { useState, useRef } from "react";
import circle from "../../images/dcc-circle.png";
import DoneIcon from "@mui/icons-material/Done";
import { Tooltip as ReactTooltip } from "react-tooltip";
import WeeklyTimeSchedule from "../WeeklyTimeSchedule";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "react-multi-carousel/lib/styles.css";
import "../SelectFacility/SelectFacility.css";
import "react-tooltip/dist/react-tooltip.css";
import { useDispatch } from "react-redux";
// import { getServices, setFacility } from "../../Redux/Actions/CommonAction";
import useCustomTheme from "../../hooks/useCustomTheme";
import { setFacility } from "../../Redux/facility/slice";
import moment from "moment";
import { useTranslation } from "react-i18next";

const FacilityCard = (props) => {
  const {
    btn_clinic_bg_color,
    activeClass
  } = useCustomTheme();
  const [flag, setFlag] = useState(true);
  const [businessHours, setBusinessHours] = useState(
    props?.item?.business_hours
  );
  const ref = useRef();
  const dispatch = useDispatch();
  const handleFlag = () => setFlag(!flag);
  // useOnClickOutside(ref, handleFlag);
  const { t } = useTranslation();
  const bookingLabels = t("data.frontLabels.booking", { returnObjects: true });


  const handleFacilityCardClick = (item) => {
    dispatch(setFacility(item))
  }
  // console.log('test');

 

  const [todayBusinessHour] = businessHours.filter(item => item.day === (new Date().getDay()));
  const currentDate = new Date();
  const fromDateTimeStr = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()} ${todayBusinessHour?.from_time}`;
  const toDateTimeStr = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()} ${todayBusinessHour?.to_time}`;
  const momentStart = moment(new Date(fromDateTimeStr)) // .format('HH:mm');
  const momentEnd = moment(new Date(toDateTimeStr)) // .format('HH:mm');
  const momentCurrent = moment(new Date())
  const start = momentCurrent.isAfter(momentStart);
  const end = momentCurrent.isAfter(momentEnd);

  const logoTest = (props?.item?.clinic_name.length>2 ? props?.item?.clinic_name.slice(0,2) : props?.item?.clinic_name).toUpperCase()  || "PET"
  // const logoTest = "Aa"

  return (
    <>
      <div className="col-12 mb-4">
        <div className="card cards-position margin-top-3-rem card-1 pointer-icon">
          <div
            className={`card-body card-padding padding-top-2-5-rem ${props.selectedFacility && props.selectedFacility.id === props?.item?.id && "active-class"
              }`}
            style={props.selectedFacility && props.selectedFacility.id === props?.item?.id ? activeClass : {}}
            onClick={() => handleFacilityCardClick(props?.item)}
          >
            <div className="card-title">
                <div className="logo-circle"> 
                  {logoTest}
                </div>
            </div>
            <div className="pointer-icon">
              {!(props.selectedFacility && props.selectedFacility.id === props?.item?.id) ? (
                <div className="checked-mark">
                  <DoneIcon
                    style={{ color: "rgba(9, 162, 62, 0.2)" }}
                  // onClick={() => handleFacilityCardClick(props?.item)}
                  />
                </div>
              ) : (
                <div className="tick-mark">
                  <DoneIcon
                    style={{ color: btn_clinic_bg_color }}
                  // onClick={() => handleFacilityCardClick(props?.item)}
                  />
                </div>
              )}
            </div>
            <div
              className="card-subtitle text-center single-line-ellipse"
              id={props?.item?.id}
            >
              {props?.item?.clinic_name}
            </div>
            <ReactTooltip
              className="tooltip-section"
              anchorId={props?.item?.id}
              place="top"
              content={props?.item?.clinic_name}
            />
            <div
              className="d-flex align-items-center justify-content-center text-center location margin-top-10 noto-font light-weight"
              id={props?.item?.address}
            >
              <div className="d-inline-block"
              // onClick={()=>{
              //   window.open(props?.item?.clinic_map_location,'_blank')
              // }}
              >
                <span className="truncated-text-1-lines">
                  {props?.item?.state},{props?.item?.city},
                  {props?.item?.address}
                </span>
              </div>
              <div
                className="d-inline-block"
                onClick={()=>{
                  window.open(props?.item?.clinic_map_location,'_blank')
                }}
              >
                <span className="margin-left-6px pointer-icon">
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipath="url(#clip0_2752_457)">
                      <path
                        d="M7.12607 7.91651C6.91611 7.91651 6.71474 7.99992 6.56628 8.14839C6.41781 8.29686 6.3344 8.49822 6.3344 8.70818V11.8748H7.91774V9.49985H10.2927V11.479L13.0636 8.70818L10.2927 5.93735V7.91651H7.12607ZM10.0608 1.10264L17.8983 8.94014C18.0467 9.0886 18.1301 9.28993 18.1301 9.49985C18.1301 9.70977 18.0467 9.9111 17.8983 10.0596L10.0608 17.8971C9.91232 18.0455 9.71099 18.1288 9.50107 18.1288C9.29115 18.1288 9.08982 18.0455 8.94136 17.8971L1.10386 10.0596C0.955445 9.9111 0.87207 9.70977 0.87207 9.49985C0.87207 9.28993 0.955445 9.0886 1.10386 8.94014L8.94136 1.10264C9.08982 0.954224 9.29115 0.87085 9.50107 0.87085C9.71099 0.87085 9.91232 0.954224 10.0608 1.10264Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2752_457">
                        <rect width="19" height="19" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
            </div>

            <ReactTooltip
              className="tooltip-section"
              anchorId={props?.item?.address}
              place="top"
              content={`${props?.item?.state},${props?.item?.city},${props?.item?.address}`}
            ></ReactTooltip>
            <div className="time-duration text-center margin-top-10 font-text-14px">
              {true ? (
                <span className="card-text-gray noto-font light-weight" style={{ minWidth: "85px" , display:'inline-block'}}>
                  {start && !end && todayBusinessHour && (<span className="open-color">{bookingLabels?.open}</span> )}
                  {((start && end) || !todayBusinessHour) && <span className="text-danger">{bookingLabels?.closed}</span>}
                  {!start && !end && todayBusinessHour && <span className="open-color"> {bookingLabels?.open}</span>}
                  {/* {!start && !end && todayBusinessHour && <span className="text-danger">{bookingLabels?.open_at} {todayBusinessHour?.from_time}</span>} */}

                </span>
              ) : (
                // <span className="card-text-gray noto-font light-weight" ref={ref} style={{ minWidth: "85px" , display:'inline-block' }}>{bookingLabels?.open_now}</span>
                <span className="card-text-gray noto-font light-weight" ref={ref} style={{ minWidth: "85px" , display:'inline-block' }}>{bookingLabels?.hour_label} :</span>
              )}
              <span onClick={(e) => e.stopPropagation()}>
                <WeeklyTimeSchedule
                  businessHours={businessHours}
                  action={
                    // <span className="down-tip-color pointer-icon" style={{marginLeft:'10px'}}>
                    //   <KeyboardArrowDownIcon onClick={handleFlag} />
                    // </span>
                    <span className="down-tip-color pointer-icon">
                    <KeyboardArrowDownIcon onClick={(e) => handleFlag(e)} />
                  </span>
                  
                  }
                />
              </span>
            </div>
            <div className="d-flex justify-content-between card-text email-phone text-center section-gap truncate mb-1 margin-top-10 noto-font">
              {props?.item?.appointment_notification_emails ? (
                <div className="card-text-color txt-decoration overflow-hidden">
                  <svg
                    style={{ color: (!(props.selectedFacility && props.selectedFacility.id === props?.item?.id))? btn_clinic_bg_color: "white"}}
                    width="16.67"
                    height="15"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.3307 5.98268V13.4993C17.3307 14.416 16.5807 15.166 15.6641 15.166H2.33073C1.41406 15.166 0.664062 14.416 0.664062 13.4993L0.672396 3.49935C0.672396 2.58268 1.41406 1.83268 2.33073 1.83268H10.7474C10.6974 2.09935 10.6641 2.38268 10.6641 2.66602C10.6641 2.94935 10.6974 3.23268 10.7474 3.49935H2.33073L8.9974 7.66602L12.0557 5.75768C12.4474 6.11602 12.9057 6.39102 13.4141 6.57435L8.9974 9.33268L2.33073 5.16602V13.4993H15.6641V6.74935C16.2807 6.62435 16.8474 6.34935 17.3307 5.98268ZM12.3307 2.66602C12.3307 4.04935 13.4474 5.16602 14.8307 5.16602C16.2141 5.16602 17.3307 4.04935 17.3307 2.66602C17.3307 1.28268 16.2141 0.166016 14.8307 0.166016C13.4474 0.166016 12.3307 1.28268 12.3307 2.66602Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span className="noto-font light-weight margin-left-6px"></span>
                  <a
                    className=""
                    href={`mailto:${props?.item?.appointment_notification_emails}`}
                    style={{ color: (!(props.selectedFacility && props.selectedFacility.id === props?.item?.id))? btn_clinic_bg_color: "white"}}
                  >
                    {props?.item?.appointment_notification_emails}
                  </a>
                </div>
              ) : (
                <div></div>
              )}
              <div className="fourth-svg card-text-color">
                <svg
                  style={{ color: (!(props.selectedFacility && props.selectedFacility.id === props?.item?.id))? btn_clinic_bg_color: "white"}}
                  width="15"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.625 15C12.8333 15 11.0869 14.6042 9.38583 13.8125C7.68417 13.0208 6.17722 11.9653 4.865 10.6458C3.55222 9.32639 2.49667 7.81583 1.69833 6.11417C0.899445 4.41306 0.5 2.66667 0.5 0.875V0.4375C0.5 0.284722 0.513889 0.138889 0.541667 0H5.41667L6.1875 4.1875L3.8125 6.58333C4.39583 7.58333 5.12861 8.52083 6.01083 9.39583C6.8925 10.2708 7.86111 11.0278 8.91667 11.6667L11.3333 9.25L15.5 10.0833V14.9583C15.3611 14.9722 15.2153 14.9828 15.0625 14.99C14.9097 14.9967 14.7639 15 14.625 15Z"
                    fill="currentColor"
                  />
                </svg>
                <span className="noto-font light-weight margin-left-6px ">
                  <a
                    className="select-card"
                    href={`tel:${props?.item?.phone_no_code}`}
                    style={{ color: (!(props.selectedFacility && props.selectedFacility.id === props?.item?.id))? btn_clinic_bg_color: "white"}}
                  >
                    {props?.item?.phone_no_code}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(FacilityCard);
