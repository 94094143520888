import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import Calendar from "react-calendar";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getSlots } from "../Redux/calendar/thunk";
import { showToast } from "../Toaster";
import "./ModalBox.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { rescheduleAppointment } from "../Redux/appointment/thunk";
import useCustomTheme from "../hooks/useCustomTheme";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { TOAST_TIMER } from "../Constants/Others";
import { CircularProgress } from "@mui/material";
import "../Constants/ja"
import useCurrentLanguage from "../hooks/useCurrentLanguage";
import { LANGUAGES } from "../Constants/Constant";

const RescheduleModal = (props) => {
  const lang = useCurrentLanguage();

  const locale = lang === LANGUAGES.JP ? "ja" : lang; //for creact-calendar
  moment.locale(lang); //for moment.js

  let globaltimeout = null;
  const { setReschedule, appointment, reschedule } = props;
  const { t } = useTranslation();
  const {
    activeClass,
    btn_clinic_bg_color
  } = useCustomTheme();
  const formLabels = t("data.frontLabels", { returnObjects: true });
  const frontMessages = t("data.frontMessages", { returnObjects: true });
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [recentError, setRecentError] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  const [selectedTimeValue, setSelectedTimeValue] = useState("");
  const calendarReducer = useSelector(state => state.calendar);
  const { timeSlots } = calendarReducer;
  const appointmentReducer = useSelector(state => state.appointment);
  const { loading, rescehduleSuccess, rescehduleError } = appointmentReducer;
  const appointmentId = appointment.id;

  const handleCloseReschedule = (event, reason) => {
    setSelectedDate(moment(new Date()));
    setSelectedTimeValue("");
    setReason("");
    setReschedule(false);
  }


  const resetState = () => {
    setReason("");
    setShowCalendar("");
    setSelectedDate(moment(new Date()));
    setSelectedTimeValue("");
  }
  useEffect(() => {
    handleChangeDate(new Date());
  }, [appointment]);

  useEffect(() => {
    if (rescehduleSuccess) {
      toast.success(`${formLabels?.booking?.apt_rsdl}` || `Appointment rescheduled`);
      setReschedule(false);
      resetState();
    }
  }, [rescehduleSuccess]);

  useEffect(() => {
    if (rescehduleError && formLabels?.booking?.BOOK_CTL_TIMESLOT_REQ !== recentError) {
      toast.error(`${formLabels?.booking?.fail_apt_rsdl}` || "Failed to reschedule appointment.", { autoClose: TOAST_TIMER });
      setRecentError(formLabels?.booking?.fail_apt_rsdl)
      if(globaltimeout) clearTimeout(globaltimeout)
                // eslint-disable-next-line react-hooks/exhaustive-deps
      globaltimeout = setTimeout(()=>{
          setRecentError(null)
      },2700)
      resetState();
    }
  }, [rescehduleError]);

  const handleSelectedSlot = (event) => {
    setSelectedTimeValue(event.target.value);
  };

  const handleChangeDate = (dateValue) => {
    const formattedDate = moment(dateValue).format("YYYY-MM-DD")
    const payload = {
      // primary
      clinicId: appointment.clinic_id,
      serviceId: appointment.service_id,
      providerId: appointment.doctor_id,
      date: formattedDate,
      scheduleType: "in_person"
    }
    dispatch(getSlots(payload));
    setShowCalendar(false);
    setSelectedDate(moment(dateValue));
  };

  const handleRescheduleAppointment = async () => {

    const payload = {
      appointment_id: appointmentId,
      select_date: selectedDate.format("YYYY-MM-DD"),
      time_slot: selectedTimeValue,
      reason: reason,
    };
    // selectedTimeValue?dispatch(rescheduleAppointment(payload)):toast.error("Select time slot", {autoClose: TOAST_TIMER})
    if (selectedTimeValue) {
      dispatch(rescheduleAppointment(payload))
    } else if (recentError !== frontMessages.BOOK_CTL_TIMESLOT_REQ) {
      toast.error(`${frontMessages?.BOOK_CTL_TIMESLOT_REQ}` || "Select time slot", { autoClose: TOAST_TIMER })
      setRecentError(frontMessages.BOOK_CTL_TIMESLOT_REQ || "Select time slot")
      if(globaltimeout) clearTimeout(globaltimeout)
      globaltimeout = setTimeout(()=>{
        setRecentError(null)
      },2500)
    }
};

return (
  <>
    <div>
      <Modal show={reschedule} scrollable={true} onClose={handleCloseReschedule} centered style={{ overflowY: 'hidden' }}>
        <Modal.Header>
          <Modal.Title>{formLabels?.booking?.reschedule_appointment}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '65vh', padding: "0px 10px" }} className="reshedule-body-style">
          <p>{formLabels?.booking?.reschedule_popup_details_label}</p>
          <p>
            {formLabels?.booking?.ancr_clinic} : {`${appointment?.clinic?.clinic_name}`}
          </p>
          <p>
            {formLabels?.thankyou?.service} : &nbsp; {`${appointment?.service?.name}`}
          </p>
          <p style={{ borderBottom: "1px solid black" }}>
            {formLabels?.booking?.select_timeslot}
          </p>


          <div className=" px-3 py-2 seeBorder row m-auto">
            {/* right box */}
            <div className=" col-6 box-conainer border-rounded pointer p-2 justify-content-center ">
              {/* green box */}
              {/* <div className="green-box border-rounded">
              </div> */}
              {/* date picker */}
              <div>
                <div onClick={() => setShowCalendar(!showCalendar)}>
                  {locale === "ja" ?selectedDate && selectedDate.format("MMM D日"):selectedDate && selectedDate.format("MMM D")} <ArrowDropDownIcon />
                </div>
                <div style={{ position: "absolute", top: "1%", left: "5%" }}>
                  {showCalendar && (
                    <Calendar
                      locale={`${locale}-GB`}
                      onChange={handleChangeDate}
                      value={selectedDate.format()}
                      minDate={new Date()}
                      // tileDisabled={({ date }) =>
                      //   date.getDay() === 0 || date.getDay() === 6
                      // } 
                    />
                  )}
                </div>
              </div>

            </div>
            {/* left box */}
            <div className="box-conainer border-rounded   pointer col-6 p-2 ms-4">
              {/* date picker */}
              <div>
                {!calendarReducer.loading ? <select className="form-select"
                  value={selectedTimeValue}
                  onChange={handleSelectedSlot}
                  style={{ border: '0' }}
                >
                  <option value=""> {formLabels?.booking?.select_slot} </option>
                  {timeSlots?.map((item, index) => (
                    <option key={`${item}`} value={item}>
                      {item}
                    </option>
                  ))}
                  {
                    selectedTimeValue === "" && <span></span>
                  }
                </select>  : <CircularProgress />}
                
              </div>
            </div>
          </div>
          {/* end new box code */}
          <div className="mb-3">
            <label
              htmlFor="exampleFormControlTextarea1"
              className="form-label"
            >
              {formLabels?.booking?.reason}
            </label>
            <textarea
              className="form-control"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              rows="3"
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            !loading &&
            (
              <>
                <Button
                  className="close-btn"
                  variant="secondary"
                  onClick={() => handleCloseReschedule()}
                >
                  {formLabels?.booking?.close}
                </Button>
                <Button
                  className="reschedule-btn"
                  style={activeClass}
                  variant="primary"
                  onClick={handleRescheduleAppointment}
                >
                  {formLabels?.booking?.reschedule}
                </Button>
              </>
            )
          }
          {
            loading &&
            <TailSpin
              height="35"
              width="35"
              color={btn_clinic_bg_color}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{ marginBottom: "8px" }}
              wrapperClass="d-flex justify-content-center"
              visible={true}
            />
          }
        </Modal.Footer>
      </Modal>
    </div>
  </>
);
};
export default React.memo(RescheduleModal);
