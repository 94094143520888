import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RescheduleModal from "../RescheduleModal";
import CancelModal from "../CancelModal";
import { Images } from "../../Constants/Images";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import NoDataAvailable from "../NoDataAvailable";
import { useTranslation } from "react-i18next";
import "./ManageBookings.css";
import { Link } from "react-router-dom";
import { getAppointments } from "../../Redux/appointment/thunk";
import useCustomTheme from "../../hooks/useCustomTheme";
import { resetCalendar } from "../../Redux/calendar/slice";
import { resetService } from "../../Redux/service/slice";
import { resetDoctor } from "../../Redux/doctor/slice";
import { resetFacility } from "../../Redux/facility/slice";
import { resetPet } from "../../Redux/pet/slice";
import { resetAppointment } from "../../Redux/appointment/slice";
import useConfig from "../../hooks/useConfig";
import { Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import FooterSection from "../Footer/FooterSection";
import LogoutIcon from '@mui/icons-material/Logout';
import logo from "../../images/logo.png"

const ManageBookings = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    activeClass
  } = useCustomTheme();
  const { config } = useConfig();
  const { logoUrl } = config;
  const dispatch = useDispatch();
  const formLabels = t("data.frontLabels", { returnObjects: true });
  const frontMessages = t("data.frontMessages", { returnObjects: true });
  
  const appointmentReducer = useSelector(state => state?.appointment);
  const { appointments } = appointmentReducer;
  const [reschedule, setReschedule] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [selected, setSelected] = useState('');
  const [moreDescription, setMoreDescription] = useState(null);



  useEffect(() => {
    if (!cancel && !reschedule) {
      dispatch(getAppointments());
    }
  }, [cancel, reschedule])

  const onHandleBookAppointment = () => {
    dispatch(resetAppointment())
    dispatch(resetCalendar());
    dispatch(resetService());
    dispatch(resetDoctor());
    dispatch(resetFacility());
    dispatch(resetPet());
  }

  const logout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("lang_type");
    window.location.reload();
  }
  
  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="container-area-padding " style={{ minHeight: "100vh" }}>
        <div className="d-flex justify-content-between my-4 ">
          <div className="d-flex justify-content-center align-items-center">
            <div className="login-header-logo-container p-0 justify-content-start">
              <img
                className="a_alda_logo_horizontal-2-2 fixed-box-image cursor-pointer"
                src={logoUrl}
                alt="aalda logo"
                onClick={() => {
                  navigate('/');
                }}
                onError={(e) => e.target.src = logo}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center module-head">
            {/* <div data-letters={`${clientData?.firstname?.charAt(0)?.toUpperCase()} ${clientData?.kana_lastname?.charAt(0)?.toUpperCase()}`}></div>
          <div className="hide-for-mobile">{`${clientData?.firstname ? clientData.firstname.charAt(0).toUpperCase() + clientData.firstname.slice(1) : ''} ${clientData?.kana_lastname ? clientData.kana_lastname.charAt(0).toUpperCase() + clientData.kana_lastname.slice(1) : ''}`}</div> */}
            {/* <div onClick={() => { removeClientsData(); window.location.reload() }}>
            <button type="button" className={`manage-btn btn btn-success`} style={{ minWidth: '112px', marginLeft: '10px', ...activeClass }}>{formLabels?.booking?.logout}</button>
          </div> */}
            <span onClick={logout} className="cursor-pointer">
              <Tooltip title={formLabels?.booking?.logout}>
                <LogoutIcon />
              </Tooltip>
            </span>
            {/* <span 
            className="pointer-icon"
            onClick={handleClick}
          >
            <SettingsIcon />
          </span> */}
            {/* MY ACCOUNT DROPDOWN MENU */}
            {/* <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem>
              <Link to={`/manage`}
                style={{color: "black"}}
                onClick={()=>window.reload()}
              >
                {formLabels?.booking?.manage_booking}
              </Link>
            </MenuItem>
            <MenuItem onClick={logout}>{formLabels?.booking?.logout}</MenuItem>
          </Menu> */}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center title-area pb-2" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.175)" }}>
          <h4>{formLabels?.booking?.upcoming_appointments}</h4>
          <div>
            <Link to={`/booking`}>
              <button
                type="button"
                className="manage-btn btn btn-success min-width-for-mobile"
                style={activeClass}
                onClick={onHandleBookAppointment}
              >
                {formLabels?.booking?.book_appointment}
              </button>
            </Link>
          </div>
        </div>

        {appointments && appointments.length > 0 ? appointments?.map((item, index) => (
          <div className="d-flex flex-column mt-4 mb-4 user-data-list" key={`index ${index}`}>
            <div className="d-flex justify-content-between align-items-sm-end flex-direction-column-mobile">
              <h5>
                <div  style={{ fontSize: '13px', fontWeight: '700', color: 'rgb(52, 58, 64,0.6)' }}><CalendarTodayIcon />&nbsp;{`${item?.appointment_datetime}`}</div>
                {formLabels?.booking?.ancr_clinic}: {`${item?.clinic?.clinic_name}`} &nbsp; &nbsp;<span className="status badge bg-success mt-2" style={{ textTransform: 'capitalize' }}>{`${frontMessages?.BOOKED || item?.appointment_color_status}`}</span>
              </h5>
              <div className="d-flex">
                <div onClick={() => { setReschedule(!reschedule); setSelected(item) }}><button type="button" className="manage-btn btn btn-success text-nowrap mt-sm-0 " style={activeClass}>{formLabels?.booking?.reschedule}</button></div>
                &nbsp; &nbsp; <div onClick={() => { setCancel(!cancel); setSelected(item) }}><button type="button" className="cancel-btn btn btn-success text-nowrap mt-sm-0">{formLabels?.booking?.cancel}</button></div>
              </div>
            </div>
            <div style={{ marginTop: "15px" }}>
              <div className="row">
                <div className="block">
                  <h4>{formLabels?.thankyou?.service}</h4>
                  <h6>{`${item?.service?.name}`}</h6>
                </div>
                <div className="block">
                  <h4>{formLabels?.booking?.pet}</h4>
                  <h6>{`${item?.pet?.name}`}</h6>
                </div>
                <div className="block">
                  <h4>{formLabels?.thankyou?.doctor}</h4>
                  {(item?.doctor?.lastname !== undefined && item?.doctor?.firstname !== undefined) ? <h6>{`${item?.doctor?.lastname} ${item?.doctor?.firstname}`}</h6> : <h6>{formLabels?.thankyou?.n_a}</h6>}
                </div>
              </div>
              {item?.appointment_notes && <div className="row">
                <div className="col-12">
                   <h4>{formLabels?.contact?.appt_notes}</h4>
                  {
                    item?.appointment_notes &&
                      item?.appointment_notes?.length > 400 ?
                      <p style={{
                        overflowWrap: "anywhere"
                      }}>{item?.appointment_notes?.substring(0,
                        moreDescription &&
                          moreDescription.id === item.id ? item?.appointment_notes?.length : 400
                      )}</p> :
                      <p style={{
                        overflowWrap: "anywhere"
                      }}>{item?.appointment_notes}</p>
                  }
                  {item?.appointment_notes?.length > 400 &&
                    <span
                      className="text-reset pointer-icon"
                      onClick={() => {
                        setMoreDescription((prev) => {
                          if (prev && prev.id === item.id) return null;
                          return {
                            id: item.id
                          }

                        })
                      }}
                    >
                      {moreDescription && moreDescription.id === item.id ? "Show less" : "Show more"}
                    </span>
                  }
                </div>
              </div>}
              
            </div>
          </div>
        )) : <p><NoDataAvailable images={Images.no_slot} headMessage={formLabels?.booking?.there_is_no_bookings_availabl} subHeadMessage={formLabels?.booking?.please_do_your_booking_first} message={formLabels?.booking?.no_bookings_are_found} /></p>}

      </div>
      <FooterSection padding={"6px"} />
      {/* Modal code  */}
      {!!reschedule && <RescheduleModal reschedule={reschedule} setReschedule={setReschedule} appointment={selected} />}
      {cancel && <CancelModal setCancel={setCancel} selected={selected} />}

    </div>
  );
};

export default React.memo(ManageBookings);
