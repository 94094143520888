export const LANGUAGES = {
    JP: "jp",
    EN: "en"

}
export const baseUrl = (()=>{
    const ENV_BASE_PATH = process.env.REACT_APP_BASE_URL
    const hostname = window.location.hostname
    const subdomain = hostname?.split('.')?.[0]
    const finalBase = ENV_BASE_PATH?.replace('subdomain', subdomain)
    return finalBase;
}) ()
