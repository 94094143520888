import { createSlice } from "@reduxjs/toolkit";
import { cancelAppointment, createAppointment, getAppointments, rescheduleAppointment } from "./thunk";

const InitialState={
    loading: false,
    error: false,
    appointments: null,
    appointmentSuccess: null,
    appointmentError: null,
    rescehduleSuccess: false,
    rescehduleError: false,
    cancelSuccess: false,
    cancelError: false
};

const appointmentSlice = createSlice({
    name:"Appointment",
    initialState: InitialState,
    reducers: {
        resetAppointment(state, action) {
            return InitialState;
        }
    },
    extraReducers: (builder) => {
        // create appointment
        builder.addCase(createAppointment.pending, (state, payload) => {
            state.loading = true;
            state.appointmentSuccess = null;
            state.appointmentError = null;
            state.rescehduleSuccess = false;
            state.rescehduleError = false;
            state.cancelSuccess = false;
            state.cancelError = false;
        })
        .addCase(createAppointment.fulfilled,(state,{payload})=>{
            state.loading=false;
            state.appointmentSuccess = payload.message;
        })
        .addCase(createAppointment.rejected,(state,{payload})=>{
            state.loading = false;
            state.appointmentError = payload;
        })
        // GET APPOINTMENTS
        .addCase(getAppointments.pending, (state, payload) => {
            state = {...InitialState, loading: true};
        })
        .addCase(getAppointments.fulfilled,(state,{payload})=>{
            state.loading=false;
            state.appointments = payload.appointments;
        })
        .addCase(getAppointments.rejected,(state,{payload})=>{
            state.loading = false;
            state.appointmentError = payload;
        })
        // rescheduleAppointment
        .addCase(rescheduleAppointment.pending, (state, payload) => {
            state.loading = true;
            state.appointmentSuccess = null;
            state.appointmentError = null;
            state.rescehduleSuccess = false;
            state.rescehduleError = false;
            state.cancelSuccess = false;
            state.cancelError = false;
        })
        .addCase(rescheduleAppointment.fulfilled,(state,{payload})=>{
            state.loading=false;
            state.appointments = payload.appointments;
            state.rescehduleSuccess = true;
        })
        .addCase(rescheduleAppointment.rejected,(state,{payload})=>{
            state.loading = false;
            state.rescehduleError = payload;
        })
        // cancelAppointment
        .addCase(cancelAppointment.pending, (state, payload) => {
            state.loading = true;
            state.appointmentSuccess = null;
            state.appointmentError = null;
            state.rescehduleSuccess = false;
            state.rescehduleError = false;
            state.cancelSuccess = false;
            state.cancelError = false;
        })
        .addCase(cancelAppointment.fulfilled,(state,{payload})=>{
            state.loading=false;
            state.cancelSuccess = true;
        })
        .addCase(cancelAppointment.rejected,(state,{payload})=>{
            state.loading = false;
            state.cancelError = payload;
        })
    },
});

export const {resetAppointment} = appointmentSlice.actions;
export default appointmentSlice.reducer;